import React, {FC, useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";

import {RouteLeavingGuard} from "./RouteLeavingGuard";
import {useGlobalModalContext} from "../MSGlobalModal";
import {MODAL_TYPES} from "../MSGlobalModal/constants";


interface PageLeavingGuardProps {
    hasChanges: boolean,
    showModal: boolean,
    setShowModal: (isShowModal: boolean) => void,
    handleApproveLeavingPage: () => void,
    shouldCheckUnload?: boolean;
}

const isExternalURL = (url: string) => new URL(url).origin !== location.origin;

export const PageLeavingGuard: FC<PageLeavingGuardProps> = ({hasChanges, showModal, setShowModal, handleApproveLeavingPage, shouldCheckUnload = true}) => {
    const history = useHistory();
    const {showModal: showGlobalModal} = useGlobalModalContext();
    const [nextLocation, setNextLocation] = useState(null);
    const hasChangesRef = useRef(hasChanges);

    useEffect(() => {
        hasChangesRef.current = hasChanges;
        const elements = [
            ...Array.from(document.getElementsByClassName("ms-layout-menu-item")[0].getElementsByTagName("a")),
            ...Array.from(document.querySelectorAll(".ms-layout-sub-menu .ms-layout-menu-item a"))
        ];
        const clickHandler = function(e: MouseEvent) {
            if (hasChangesRef.current) {
                e.preventDefault();
                setNextLocation(this.getAttribute("href"));
            }
        };
        elements.forEach((element) => {
            element.addEventListener("click", clickHandler.bind(element));
        });
        if (shouldCheckUnload) {
            window.onbeforeunload = (event) => {
                if (hasChangesRef.current) {
                    event.preventDefault();
                    if (event) {
                        event.returnValue = "Are you sure you want to continue?";
                    }
                    return "";
                }
            };
        }
        return () => {
            elements.forEach((element) => {
                element.removeEventListener("click", clickHandler);
            });

            hasChangesRef.current = false;
        };
    }, [hasChanges]);

    useEffect(() => {
        showModal && showGlobalModal(MODAL_TYPES.CONFIRM_MODAL, {
            title: "You have unsaved changes",
            text: "Are you sure you want to continue?",
            onConfirm: handleApproveLeavingPage,
            onCancel: () => setShowModal(false)
        });
    }, [showModal]);


    return (
        <>
            <RouteLeavingGuard
                when={hasChanges}
                navigate={(path: string) => {
                    if (nextLocation) {
                        const isExternal = isExternalURL(nextLocation);
                        if (isExternal) return window.location.href = nextLocation;

                        const url = new URL(nextLocation);
                        if (!url.hash) return history.push(url.pathname);

                        history.push(url.hash.slice(1));

                    } else {
                        history.push(path);
                    }
                }}
                shouldBlockNavigation={() => hasChanges}
                nextLocation={nextLocation}
                onCancel={() => setNextLocation(null)}
            />
        </>
    );
};
